<template>
  <div>
    <v-container fluid>
    <v-toolbar-title>{{ $t('Home') }}</v-toolbar-title>

      <v-row class="mt-5 mx-0">
        <h1 style="margin:0 auto;font-size:42px;color:brown;">Welcome on Vision Optics </h1>
      </v-row>
      <!-- <v-row class="mt-2">
        <v-col cols="3">
          <v-card color="warning" height="100">
            <h3 class="text-center pt-5">{{ $t("Patients") }}</h3>
            <h2 class="text-center pt-3">{{ system_data.customerCount }}</h2>
          </v-card>
        </v-col>


      </v-row> -->
    </v-container>
  </div>
</template>

<script>
export default {
  name: "home",
  data() {
    return {
      system_data: {}
    };
  },
  methods: {
    initData() {
      this.$store.dispatch(`home/getData`).then((res) => {
        this.system_data = res.data;
      });
    },
  },
  created() {
    this.initData();
  },
};
</script>
